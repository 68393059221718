import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

// Amplify imports
import { Auth, Hub } from "aws-amplify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import SignIn from "./components/SignIn";
import Home from "./components/Home";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
}));

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((userData) => userData)
    .catch(() => console.log("Not signed in"));
}

function PrivateRoute({ component: Component, ...rest }) {
  const history = useHistory();
  useEffect(() => {
    getUser().then((userData) => !userData && history.push("/signin"));
  }, []);
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

function App() {
  const [user, setUser] = useState(null);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
  }, []);

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/signin">
            <SignIn />
          </Route>
          <PrivateRoute path="/">
            <Home />
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
