import React, {useContext, useEffect, useState} from "react";
import { List, ListItem, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import { API, graphqlOperation } from "aws-amplify";

// Local components
import { createNotification } from "../graphql/mutations";
import { onCreateNotification } from "../graphql/subscriptions";

import MenuBar from "./MenuBar";
import MovieCard from "./MovieCard";
import SelectMovieView from "./SelectMovieView";
import { notificationsContext } from "../notifications"

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
}));

function App() {
  const [movieList, setMovieList] = useState([]);
  const [movieAlert, setMovieAlert] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [movieSubscription, setMovieSubscription] = useState(null);
  const notifications = useContext(notificationsContext)
  const classes = useStyles();

  useEffect(() => {
    async function loadMovies() {
      var response = await API.get("WhatTheFilm", "/api/movies");
      var movieList = response;
      var sortedList = movieList.sort((a, b) => b.votes - a.votes);
      setMovieList(sortedList);
    }

    loadMovies();
  }, []);

  useEffect(() => {
    if(movieSubscription) {
      movieSubscription.unsubscribe();
    }

    var subscription = API.graphql(graphqlOperation(onCreateNotification)).subscribe(
      (notificationData) => {
        var newNotification =
          notificationData.value.data.onCreateNotification;

        if(!notifications.includes(newNotification.id) ) {
          newNotification.metadata = JSON.parse(newNotification.metadata);

          if (newNotification.type === "CREATE_MOVIE") {
            movieList.push(newNotification.metadata);
            setMovieList(movieList);
            setMovieAlert({ severity: "success", message: `${newNotification.metadata.title} was added to the list!` });
          } else if (newNotification.type === "DELETE_MOVIE") {
            let movieId = newNotification.metadata.id;
            let updatedList = movieList.filter((m) => m.id !== movieId);
            setMovieList(updatedList);
            setMovieAlert({ severity: "error", message: `${newNotification.metadata.title} was removed from the list!` });
          } else if (newNotification.type === "UPDATE_MOVIE") {
            let movieId = newNotification.metadata.id
            let movieVote = newNotification.metadata.vote
            let updatedList = movieList.map((movie) => {
              if (movie.id === movieId) {
                movie.votes += movieVote
              }
              return movie
            })
            setMovieList(updatedList)
          }
        }
      });
    setMovieSubscription(subscription);
  }, [movieList]);

  async function addMovie(movie) {
    API.post("WhatTheFilm", "/api/movies", {
      body: {
        id: movie.id,
      },
    });

    var movieDetails = await API.get("WhatTheFilm", `/api/movies/${movie.id}`);
    var movieDetailsWithVotes = movieDetails;
    movieDetailsWithVotes.votes = 0;

    const newNotification = await API.graphql(
      graphqlOperation(createNotification, {
        input: {
          type: "CREATE_MOVIE",
          metadata: JSON.stringify(movieDetailsWithVotes),
        },
      })
    );

    notifications.push(newNotification.data.createNotification.id)

    movieList.push(movieDetailsWithVotes);
    setMovieList(movieList);
  }

  async function deleteMovie(movie) {
    const deleteNotification = await API.graphql(
      graphqlOperation(createNotification, {
        input: {
          type: "DELETE_MOVIE",
          metadata: JSON.stringify({"id": movie.id, "title": movie.title}),
        },
      })
    );
    notifications.push(deleteNotification.data.createNotification.id)

    var updatedList = movieList.filter((m) => m.id != movie.id);
    setMovieList(updatedList);
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function handleClose() {
    setMovieAlert(null);
  }

  return (
    <div className={classes.root}>
      <MenuBar />
      <Snackbar
        open={movieAlert !== null}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        {movieAlert &&
          <Alert severity={movieAlert.severity} onClose={handleClose}>
            <div>{movieAlert.message}</div>
          </Alert>
        }
      </Snackbar>

      <div>
        <List>
          {movieList.map((movieItem) => {
            return (
              <ListItem>
                <MovieCard
                  movieList={movieList}
                  setMovieList={setMovieList}
                  movie={movieItem}
                  onDelete={deleteMovie}
                />
              </ListItem>
            );
          })}
        </List>

        <Fab
          color="primary"
          size="small"
          aria-label="add"
          style={{ position: "fixed", right: "20px", bottom: "20px" }}
          onClick={(e) => setDialogOpen(true)}
        >
          <AddIcon />
        </Fab>

        <SelectMovieView
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          onSubmit={async (movie) => {
            await addMovie(movie);
            setDialogOpen(false);
          }}
        />
      </div>
    </div>
  );
}

export default App;
