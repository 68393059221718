import { API } from "aws-amplify";
import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { Button, TextField, useMediaQuery } from "@material-ui/core";
import { debounce } from "throttle-debounce";

function SelectMovieView(props) {
  const { open } = props;
  const [movie, setMovie] = useState({ title: "" });
  const [movieSearchResults, setMovieSearchResults] = useState([]);
  const autocompleteSearchDebounce = debounce(500, movieSearch);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  async function movieSearch(title) {
    if (title === "") {
      setMovieSearchResults([]);
    } else {
      var movieResults = await API.get(
        "WhatTheFilm",
        `/api/movies/search?query=${title}`
      );
      setMovieSearchResults(movieResults.results);
    }
  }

  function changeQuery(event) {
    autocompleteSearchDebounce(event);
  }

  function getReleaseDate(movie) {
    if (movie.release_date) {
      return movie.release_date.split("-")[0];
    }
    return "";
  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      fullScreen={fullScreen}
      open={open}
      onClose={props.handleClose}
    >
      <DialogTitle id="simple-dialog-title">Search for a Movie</DialogTitle>
      <Autocomplete
        style={{ margin: "10px" }}
        value={movie}
        options={movieSearchResults}
        getOptionSelected={(option, value) => option.title === value.title}
        getOptionLabel={(m) => m.title}
        blurOnSelect
        onChange={(e, newValue) => setMovie(newValue)}
        onInputChange={(e, newValue) => changeQuery(newValue)}
        renderOption={(option) => (
          <div>
            {option.title}: {getReleaseDate(option)}
          </div>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined"></TextField>
        )}
      />
      {movie && movie.title !== "" && (
        <div style={{ marginLeft: "20px", marginTop: "20px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{}}>
              <img
                src={`https://image.tmdb.org/t/p/w200/${movie.poster_path}`}
                alt=""
              ></img>
            </Grid>
            <Grid item xs={12}>
              <div>
                <b>Movie</b>: {movie.title}
              </div>
              <div>
                <b>Description</b>: {movie.overview}
              </div>
              <div>
                <b>Date:</b> {getReleaseDate(movie)}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      <div style={{ textAlign: "center", margin: "10px" }}>
        <Button
          variant="contained"
          onClick={() => {
            props.handleClose();
          }}
        >
          Cancel
        </Button>

        <Button
          style={{ marginLeft: "10px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            props.onSubmit(movie);
            setMovie({ title: "" });
          }}
        >
          Submit
        </Button>
      </div>
    </Dialog>
  );
}

export default SelectMovieView;
