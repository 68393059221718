import React from "react";
import { Typography } from "@material-ui/core";

function MovieAttribute(props) {
  return (
    <div>
      <Typography variant="subtitle2" style={{ display: "inline" }}>
        {props.name}:
      </Typography>{" "}
      {props.value}
    </div>
  );
}

export default MovieAttribute;
