import React from "react";
import Rating from "@material-ui/lab/Rating";
import { Typography } from "@material-ui/core";

function MovieRating(props) {
  return (
    <React.Fragment>
      <Typography variant="subtitle2">Review:</Typography>
      <Rating name="Review" readOnly value={props.review / 2} precision={0.5} />
    </React.Fragment>
  );
}

export default MovieRating;
