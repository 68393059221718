import React from "react";
import MovieAttribute from "./MovieAttribute";
import MovieRating from "./MovieRating";

function MovieAttributes(props) {
  function getReleaseDate(movie) {
    if (movie.release_date) {
      return movie.release_date.split("-")[0];
    }
    return "";
  }

  function getMovieLength(movie) {
    var runtime = movie.runtime;
    if (runtime > 60) {
      var minutes = runtime % 60;
      var hours = Math.floor(runtime / 60);
      return `${hours}h ${minutes}m`;
    } else {
      return runtime + " min";
    }
  }

  return (
    <div
      style={{
        padding: "10px",
      }}
    >
      <MovieAttribute name="Title" value={props.movie.title}></MovieAttribute>
      <MovieAttribute
        name="Genres"
        value={props.movie.genres.map((genre) => genre.name).join(", ")}
      ></MovieAttribute>
      <MovieAttribute name="Year" value={getReleaseDate(props.movie)} />
      <MovieAttribute name="Length" value={getMovieLength(props.movie)} />
      <MovieRating review={props.movie.vote_average} />
    </div>
  );
}

export default MovieAttributes;
