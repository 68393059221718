import React from "react";
import { Typography } from "@material-ui/core";

function MovieDescription(props) {
  return (
    <div
      style={{
        backgroundColor: "#f1f1f1",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <Typography variant="subtitle2">Description:</Typography>
      <div>{props.description}</div>
    </div>
  );
}

export default MovieDescription;
