import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MovieIcon from "@material-ui/icons/Movie";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { Auth } from "aws-amplify";

MenuBar.propTypes = {};

const useStyles = makeStyles({
  list: { width: 250 },
  title: { flexGrow: 1 },
});

function MenuBar(props) {
  const classes = useStyles();
  const [drawIsOpen, setDrawIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar style={{ width: "100%" }} position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawIsOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Movie Night
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  Auth.signOut();
                  handleClose();
                }}
              >
                Sign Out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawIsOpen}
        onClose={() => setDrawIsOpen(!drawIsOpen)}
      >
        <List className={classes.list}>
          <ListItem>
            <ListItemIcon>
              <MovieIcon />
            </ListItemIcon>
            <ListItemText primary="Movie List" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <EmojiEventsIcon />
            </ListItemIcon>
            <ListItemText primary="Top 10" />
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default MenuBar;
