import { Button } from "@material-ui/core";
import React, {useContext} from "react";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import {API, graphqlOperation} from "aws-amplify";
import {createNotification} from "../graphql/mutations";

// Local imports
import { notificationsContext} from "../notifications";

function MovieVoting(props) {
  const notifications = useContext(notificationsContext)

  async function vote(movie, inc) {
    var updatedList = await Promise.all(
      props.movieList.map(async (m) => {
        if (m.id === movie.id) {
          if (!(movie.votes <= 0 && inc < 0)) {
            m.votes += inc;

            await API.put("WhatTheFilm", "/api/movies/" + movie.id, {
              body: {
                votes: movie.votes,
              },
            });

            const votingNotification = await API.graphql(
              graphqlOperation(createNotification, {
                input: {
                  type: "UPDATE_MOVIE",
                  metadata: JSON.stringify({"vote": inc, "id": movie.id}),
                },
              })
            );

            notifications.push(votingNotification.data.createNotification.id)
          }
        }
        return m;
      })
    );

    updatedList = updatedList.sort((a, b) => {
      return b.votes - a.votes;
    });

    props.updateList(updatedList);
  }

  return (
    <div style={{ textAlign: "center", paddingTop: "10px" }}>
      <Button
        onClick={async (e) => await vote(props.movie, 1)}
        style={{ marginRight: "20px" }}
      >
        <ThumbUpIcon style={{ color: "green" }} />
      </Button>
      <b>Votes:</b> {props.movie.votes}
      <Button
        onClick={async (e) => await vote(props.movie, -1)}
        style={{ marginLeft: "20px" }}
      >
        <ThumbDownIcon style={{ color: "red" }} />
      </Button>
    </div>
  );
}

export default MovieVoting;
