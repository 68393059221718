import React from "react";

function MoviePoster(props) {
  return (
    <React.Fragment>
      <img
        src={`https://image.tmdb.org/t/p/w154/${props.poster}`}
        alt=""
        style={{ borderRadius: "5px" }}
      ></img>
    </React.Fragment>
  );
}

export default MoviePoster;
