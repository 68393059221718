import React, { useEffect } from "react";
import GoogleButton from "react-google-button";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Card, Paper, Typography } from "@material-ui/core";

function SignIn() {
  const history = useHistory();

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  useEffect(() => {
    getUser().then((userData) => {
      if (userData) {
        history.push("/home");
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            display: "inline-block",
            padding: "40px",
            margin: "40px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" style={{ margin: "20px" }}>
            Movie Night
          </Typography>
          <div className="App">
            <GoogleButton
              style={{ display: "inline-block" }}
              onClick={() => Auth.federatedSignIn({ provider: "Google" })}
            ></GoogleButton>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default SignIn;
