import React, { useState, useEffect, useRef } from "react";
import { Grid, Card } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { API } from "aws-amplify";

import MoviePoster from "./MoviePoster";
import MovieVoting from "./MovieVoting";
import MovieDescription from "./MovieDescription";
import MovieAttributes from "./MovieAttributes";

function MovieCard(props) {
  async function deleteMe(movie) {
    await API.del("WhatTheFilm", `/api/movies/${movie.id}`);
    props.onDelete(movie);
  }

  return (
    <Card style={{ padding: "10px", width: "100%" }}>
      <div style={{ textAlign: "right", color: "red", cursor: "pointer" }}>
        <DeleteIcon onClick={() => deleteMe(props.movie)} />
      </div>
      <Grid container>
        <Grid item xs={6}>
          <MoviePoster poster={props.movie.poster_path} />
        </Grid>

        <Grid item xs={6}>
          <MovieAttributes movie={props.movie} />
        </Grid>

        <Grid item xs={12}>
          <MovieDescription description={props.movie.overview} />
        </Grid>

        <Grid item xs={12}>
          <MovieVoting
            movie={props.movie}
            updateList={props.setMovieList}
            movieList={props.movieList}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default MovieCard;
